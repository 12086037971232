<template>
  <div>
    <myheader></myheader>
    <div id="gjfx" class="content-Box3">
      <div class="title1 wow animate__fadeIn">招贤纳士</div>
      <div class="workshop-join" style="margin-bottom: 20px">
        <div class="teaching-join wow animate__fadeInUp" v-for="(teaching, index) in teachings" :key="'teachingB-' + index">
          <div class="teaching-Icon4">
            <img :src="teaching.url" class="icons"/>
          </div>
          <div class="teaching-join2">
            <div class="title">{{ teaching.title }}</div>
          </div>
          <div class="teaching-Title3">
            <div class="jobs">{{ teaching.job1 }}</div>
            <ul class="ulstyle">
              <li v-for="(content1, index) in teaching.content1" :key="'teachingContent1-' + index">{{ content1 }}</li>
            </ul>
          </div>
          <div class="teaching-Title3">
            <div class="jobs">{{ teaching.job2 }}</div>
            <ul class="ulstyle">
              <li v-for="(content2, index) in teaching.content2" :key="'teachingContent2-' + index" v-html="content2">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <mybottom></mybottom>
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';
export default {
  components: { myheader, mybottom },
  data() {
    return {
      teachings: [
        {
          url: require('../../../src/assets/icons/yunyunwei.png'),
          title: '云计算运维工程师',
          job1: '岗位职责',
          content1: ['1. 负责云计算平台架构设计、研发与交付等工作；', '2. 负责OpenStack平台实施、优化和问题排查；', '3. 负责Kubernetes平台实施、优化和问题排查；'],
          job2: '任职要求',
          content2: [
            '<b> 1. 专科以上学历，计算机相关专业；</b>',
            '2. 掌握Docker运行机制，熟悉Kubernetes（mesos, swarm, coreos）框架，可进行定制开发；熟悉KVM、Libvirt、Qemu等虚拟化相关技术及Ceph,SheepDog,LVM等存储相关技术；',
            '3. 精通Linux系统, 熟悉网络原理，熟悉Python语言开发，具备解决nova、cinder等模块核心技术问题能力；',
            '4. 具有OpenStack、Kubernetes云平台的生产系统的成功经验优先；',
            '<b>5. 全国职业院校技能大赛云计算赛项获奖优秀选手、世界职业院校技能大赛云计算赛项获奖优秀选手优先考虑；</b>',
            '6. 具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '7. 具有良好的团队合作、敬业、守规的素养；',
          ],
        },
        {
          url: require('../../../src/assets/icons/yunkaifa.png'),
          title: '云计算开发工程师',
          job1: '岗位职责',
          content1: ['1. 负责云计算平台架构设计、服务开发与优化等工作；', '2. 负责OpenStack平台服务组件的开发与优化工作；', '3. 负责Kubernetes平台服务组见开发和优化工作；'],
          job2: '任职要求',
          content2: [
            '<b>1. 专科以上学历，计算机相关专业；</b>',
            '2. 精通Python/Java/Go一种以上编程语言；',
            '3. 具有OpenStack、 Kubernetes运维或开发经验；',
            '4. 具有软件项目研发经验，熟悉DevOps、微服务等软件开发方式；',
            '<b>5. 全国职业院校技能大赛云计算赛项获奖优秀选手、世界职业院校技能大赛云计算赛项获奖优秀选手优先考虑；</b>',
            '6. 具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '7. 具有良好的团队合作、敬业、守规的素养；',
          ],
        },
        {
          url: require('../../../src/assets/icons/python.png'),
          title: 'Python开发工程师',
          job1: '岗位职责',
          content1: ['1. 负责多云管理平台设计、开发与优化等工作；', '2. 负责OpenStack接口对接开发；', '3. 负责Kubernetes接口对接开发；', '4. 负责公有云接口对接开发；'],
          job2: '任职要求',
          content2: [
            '<b>1. 专科以上学历，计算机相关专业；</b>',
            '2. 熟练使用Python等编程语言；',
            '3. 了解Python Web框架，如Flask、Django等，熟悉各种常用设计模式；',
            '4. 熟练掌握科学库如numpy、scipy、pandas、matplotlib、tensorflow、pytorch等优秀考虑；',
            '5. 熟练掌握MySQL、MongoDB原理及机制，掌握各种查询语法，具有数据库设计和SQL优化能力；',
            '<b>6. 计算机类全国职业院校技能大赛赛项获奖优秀选手、世界职业院校技能大赛赛项获奖优秀选手优先考虑；</b>',
            '7. 具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '8. 具有良好的团队合作、敬业、守规的素养；',
          ],
        },
        {
          url: require('../../../src/assets/icons/go.png'),
          title: 'Go开发工程师',
          job1: '岗位职责',
          content1: ['1. 负责Kubernetes容器拓展服务开发；', '2. 负责istio网格服务整合整合开发；', '3. 负责KubeEdge边缘计算服务整合开发；'],
          job2: '任职要求',
          content2: [
            '<b>1. 专科以上学历，计算机相关专业；</b>',
            '2. 掌握Go语言开发，熟悉Go网络开发;',
            '3. 熟悉常用的Go框架和工具，如gin、beego、grpc等开发；了解Kubernetes、Docker等容器化技术；',
            '4. 熟练使用MySQL、Redis、MongoDB等数据库，熟悉常见的负载均衡算法以及实现；',
            '5. 熟悉常用的Linux操作系统，熟悉shell编程；',
            '<b>6. 计算机类全国职业院校技能大赛赛项获奖优秀选手、世界职业院校技能大赛赛项获奖优秀选手优先考虑；</b>',
            '7. 具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '8. 具有良好的团队合作、敬业、守规的素养；',
          ],
        },
        {
          url: require('../../../src/assets/icons/java.png'),
          title: 'Java开发工程师',
          job1: '岗位职责',
          content1: [
            '1．参与公司斗学网平台服务端架构、设计、开发，撰写相关技术文档 ；',
            '2．负责系统框架和核心业务代码的实现；',
            '3．参与制定设计及实现规范，软件部署工作；',
            '4．协助持续改进软件系统架构、核心算法或者核心技术模块等，使产品在高性能和高可用性方面发挥作用；',
          ],
          job2: '任职要求',
          content2: [
            '<b>1. 专科以上学历，计算机相关专业；</b>',
            '2. 熟悉Spring、Spring Boot、Spring MVC、MyBatis等开源框架和其理念，熟悉常见的一些设计模式；',
            '3．熟悉Linux下的常用命令，熟悉Mysql，有较好的数据库表设计能力；',
            '4．熟悉Memcached、Redis、MQ、Mongodb等高性能并发中间件者优先；',
            '<b>5. 计算机类全国职业院校技能大赛赛项获奖优秀选手、世界职业院校技能大赛赛项获奖优秀选手优先考虑；</b>',
            '6. 熟悉J2EE系统架构，有Java Web项目开发经验，有大型项目开发经验者优先；',
            '7. 具有良好的编码风格和规范意识，能够编写易于维护、易于扩展的代码；',
            '8. 具备良好的团队合作精神、学习能力和沟通能力；',
          ],
        },
        {
          url: require('../../../src/assets/icons/qukuailian.png'),
          title: '区块链开发工程师',
          job1: '岗位职责',
          content1: ['1. 负责区块链项目开发，包括企业区块链企应用，区块链培训与竞赛资源开发；', '2. 负责区块链企业项目、专业实训室交付和培训；'],
          job2: '任职要求',
          content2: [
            '<b>1. 专科以上学历，计算机相关专业；</b>',
            '2. 掌握Golang/Python/ Java/等主流开发语言中的一种或多种，具有良好的编程习惯；',
            '3. 掌握区块链智能合约技术，熟悉区块链的开源项目（Ethereum、Hyperledger Fabric）；',
            '4. 具有Linux下编程和开发经验，熟悉JSON RPC架构设计；',
            '<b>5. 计算机类全国、省级职业院校技能大赛获奖优秀选手优先考虑；</b>',
            '6. 有区块链项目经验者优先；',
            '7. 了解阿里蚂蚁链、阿里云区块链服务优先；',
            '8. 具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '9. 具有良好的团队合作、敬业、守规的素养；',
          ],
        },
        {
          url: require('../../../src/assets/icons/web.png'),
          title: 'Web前端开发工程师',
          job1: '岗位职责',
          content1: ['1. 负责公司斗学网前端、小程序端开发；', '2.  Web前端开发培训用课程资源开发；', '3. 及时有效地解决开发人员所提出的问题，解决技术上的难题；'],
          job2: '任职要求',
          content2: [
            '<b>1. 专科以上学历，计算机相关专业；</b>',
            '2．掌握前端开发技术与开源框架，包括Web前端基础及Vue等框架，熟悉Unity/PlayCanvas/PhoneGap/Flutter/小程序等跨平台开发工具；',
            '3．掌握MySQL等数据库技术，熟悉PHP/NodeJS等后端技术；',
            '4．具有良好的书面表达能力，熟悉软件项目过程中各阶段所对应的文档的编写要求和编制要点；',
            '<b>5. 计算机类全国职业院校技能大赛赛项获奖优秀选手、世界职业院校技能大赛赛项获奖优秀选手优先考虑；</b>',
            '6. 持有软考系统架构设计师或者信息系统项目管理师证书者优先；',
            '7. 责任心强，具有独立担当能力，善于与他人沟通；',
            '8. 具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '9. 具有良好的团队合作、敬业、守规的素养；',
          ],
        },
        {
          url: require('../../../src/assets/icons/ddd.png'),
          title: 'Web 3D开发工程师',
          job1: '岗位职责',
          content1: ['1．负责公司云计算虚拟平台设计、开发；', '2．根据交互设计要求，设计与开发对应的应用功能；', '3．参与基于HTML5、WebGL相关产品的研发与开发工作；'],
          job2: '任职要求',
          content2: [
            '<b>1. 专科以上学历，计算机相关专业；对空间几何数学算法有一定了解，能够通过相应算法进行几何图形的绘制等开发；</b>',
            '2．熟悉计算机图形学及三维图形图像理论基础，如三维成像原理、坐标转换、UV贴图、着色器、材质等；',
            '3．有WebGL、OpenGL开发经验，熟练掌握Three.js、Babylon.js等至少一种框架者优先；',
            '4．熟悉UE4、UE5、Unity等游戏引擎中的至少一种，至少一门语言，有多语言经历优先；',
            '<b>5.全国职业院校技能大赛虚拟现实、VR等相关赛项获奖优秀选手优先考虑；</b>',
            '6．对数字孪生、虚拟现实、视觉仿真、深度学习等前沿技术有高度的热情和爱好，对新技术持有敏感性并愿意致力于新技术的探索和研究；',
            '7．积极主动、善于沟通，具有良好的抗压能力；',
            '8. 具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '9. 具有良好的团队合作、敬业、守规的素养；',
          ],
        },
        {
          url: require('../../../src/assets/icons/bianyuan.png'),
          title: '边缘计算开发工程师',
          job1: '岗位职责',
          content1: ['1. 负责公司智能边缘计算管理平台开发；', '2. 负责边缘计算服务整合开发；', '3. 负责边端智能设备接入与智能AI应用开发；'],
          job2: '任职要求',
          content2: [
            '<b>1. 专科以上学历，计算机相关专业；有边缘计算行业经验优先；</b>',
            '2. 掌握基本数据结构和算法，至少精通Java、Python、Go其中的一种；有视觉应用项目开发经验优先；',
            '3. 了解Kubernetes、KubeEdge平台，了解MQTT等物联网协议与中间件；',
            '4. 熟悉图像处理、模式识别、计算机视觉、深度学习相关技术，熟悉Caffe、TensorFlow、Yolo、Pytorch等一种或多种深度学习平台使用；',
            '<b>5. 全国职业院校技能大赛物联网等相关赛项获奖优秀选手优先考虑；</b>',
            '6. 具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '7. 具有良好的团队合作、敬业、守规的素养；',
          ],
        },
        {
          url: require('../../../src/assets/icons/jishu.png'),
          title: '技术支持工程师',
          job1: '岗位职责',
          content1: [
            '1.协助售后部门完成技术支持任务；',
            '2.为客户提供远程技术支持，包括技术咨询、故障处理等；',
            '3.梳理并总结典型问题，形成知识库；',
            '4.协助挖掘客户需求和痛点，优化产品方案，协助提高产品易用性。',
          ],
          job2: '任职要求',
          content2: [
            '<b>1.专科以上学历，计算机相关专业；</b>',
            '2.熟悉Linux常用命令，能够编写Python、Shell脚本；',
            '3.熟悉OpenStack、KVM、Kubernetes、Docker、Helm 等相关技术；',
            '4.熟悉日志分析和监控工具，如 Prometheus、Grafana、ELK 等，能够对系统性能进行监控和调优；',
            '<b>5.全国职业院校技能大赛云计算赛项获奖优秀选手、世界职业院校技能大赛云计算赛项获奖优秀选手优先考虑；</b>',
            '6.具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '7.具备良好的跨部门沟通协作能力，具备较强的责任心和执行力。',
          ],
        },
        {
          url: require('../../../src/assets/icons/jiejue.png'),
          title: '解决方案工程师',
          job1: '岗位职责',
          content1: [
            '1.负责公司产品/项目的需求跟进、解决方案制定、现场产品介绍等售前支持工作；',
            '2.负责跟进公司新业务的发展，独立对接新业务需求，完成产品解决方案的总体规划设计；',
            '3.负责项目招投标工作顺利进行，把控项目风险，完成投标工作；',
            '4.负责公司业务部门、合作伙伴的培训及项目支持。',
          ],
          job2: '任职要求',
          content2: [
            '<b>1.专科以上学历，计算机相关专业；</b>',
            '2.具有良好的书面表达能力、良好的沟通技巧、良好的团队协作精神和客户服务意识;',
            '3.对云计算以及行业典型的上云解决方案有一定了解者优先;',
            '4.责任心强，具有独立担当能力，善于与他人沟通；',
            '5.具有较强的逻辑思维、独立学习、研究新技术的能力；',
            '6.具有良好的团队合作、敬业、守规的素养。',
          ],
        },
        {
          url: require('../../../src/assets/icons/ziyuan.png'),
          title: '云计算课程资源开发工程师',
          job1: '岗位职责',
          content1: ['1.负责公司云计算的培训课程体系研发，包括云计算平台、云计算应用、云计算运维的项目研发，项目案例课程转化和项目案例课程的培训。'],
          job2: '任职要求',
          content2: [
            '<b>1.计算机、软件相关专业，本科学历以上；乐于从事培训课程开发、培训授课相关工作。</b>',
            '2.熟悉操作系统原理，熟悉Linux、Linux Kernel；',
            '3.熟悉网络原理，熟悉TCP/IP网架构和设备配置；',
            '4.熟悉虚拟机技术OpenStack、Kubernetes等平台，熟悉KVM虚拟机、Docker容器等底层技术；',
            '5.熟悉Python、Go等云计算平台开发常用语言，熟悉Linux Shell、Ansible、Puppet等运维工具。',
            '6.具有云计算项目规划、设计、实施工作经验。',
            '7.较强的分析能力和解决问题能力、良好的口头和书面表达能力；',
            '8.工作积极主动、认真负责，具有较好的团队意识和与其他团队协作的能力。',
            '<b>9.参与全国职业院校技能大赛等云计算相关赛项获奖优秀选手优先考虑。</b>',
          ],
        },
      ],
    };
  },
  created() {},
  methods: {},
  mounted() {
    var wow = new this.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
};
</script>

<style lang="less" scoped>
.content-box3 {
  height: 410px;
  background-color: #ffffff;
  /* box-shadow: 0px 0px 3px 3px #e4dcdc; */
  margin-top: 20px;
}
.title1 {
  margin-left: auto;
  margin-right: auto;
  color: black;
  font-weight: 600;
  font-size: 30px;
  padding-top: 65px;
  text-align: center;
}
.teaching-Icon4 {
  text-align: center;
  margin-top: 30px;
  width: 100%;
  height: 80px;
}
.teaching-Icon4 .icons{
  width: 80px;
  height: 80px;
}
.teaching-join2 {
  text-align: center;
  margin-top: 20px;
  font-weight: 700;
  color: black;
  font-size: 16px;
  text-align: center;
}
.teaching-join2 .title {
  font-size: 30px;
}
.teaching-join2 .line {
  width: 1px;
  height: 20px;
  background: black;
  margin: 0 10px;
}

.teaching-Title3 {
  margin: 20px 40px;
  box-sizing: border-box;
  // line-height: 2;
  font-size: 16px;
}
.workshop-join {
  /* margin-top:-10px; */
  display: flex;
  flex-flow: wrap;
  width: 1300px;
  margin: 0 auto;
  /*盒子会自动掉下来*/
}
.teaching-join {
  width: 48%;
  margin-left: 21px;
  margin-top: 30px;
  background-color: blaclk;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 1px rgb(226 222 222);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.teaching-Case {
  margin-left: 21px;
  margin-top: 30px;
}
.ulstyle {
  list-style: none;
}
.jobs {
  font-size: 20px;
  font-weight: 700;
}
</style>

